require('dotenv').config();

const alchemyKey = process.env.REACT_APP_ALCHEMY_KEY;
const { createAlchemyWeb3 } = require("@alch/alchemy-web3");
const web3 = createAlchemyWeb3(alchemyKey); 

const contractABI = require('../contract-abi.json');
const contractAddress = "0x1CB8e2B38B8Af96E9B886B5D9F956F6eaD08f600";

export const refreshStats = async (walletAddress) => {
    window.contract = await new web3.eth.Contract(contractABI, contractAddress);
    const data = {
        totalSupply: parseInt(await window.contract.methods.totalSupply().call()),
        TOTAL_LIMIT: parseInt(await window.contract.methods.TOTAL_LIMIT().call()),
    }
    try {
      data['balance'] = parseInt(await window.contract.methods.balanceOf(window.ethereum.selectedAddress).call());
    } catch (error) {
      data['balance'] = 0;
    }
    try{
        data['wallet_price'] = await window.contract.methods.getPrice(walletAddress).call() / 1e18;
    } catch (error) {
        data['wallet_price'] = 0;
    }
    try{
        data['allow_public'] = await window.contract.methods.allowPublic().call();
    } catch (error) {
        data['allow_public'] = false;
    }
    return data;
};

export const mintNFT = async () => {
  window.contract = await new web3.eth.Contract(contractABI, contractAddress);
  const price = await window.contract.methods.getPrice(window.ethereum.selectedAddress).call();
  //const price = await window.contract.methods.PRICE().call();
  const transactionParameters = {
      to: contractAddress, // Required except during contract publications.
      from: window.ethereum.selectedAddress, // must match user's active address.
      value: parseInt(price).toString(16),
      'data': window.contract.methods.mintNFT().encodeABI() //make call to NFT smart contract 
  };
  try {
      const txHash = await window.ethereum
          .request({
              method: 'eth_sendTransaction',
              params: [transactionParameters],
          });
      return {
          success: true,
          status: "✅ Check out your transaction on Etherscan: https://etherscan.io/tx/" + txHash
      }
  } catch (error) {
      return {
          success: false,
          status: "😥 Something went wrong: " + error.message
      }
  }
};

export const mintMultipleNFT = async (n) => {
  window.contract = await new web3.eth.Contract(contractABI, contractAddress);
  let price = await window.contract.methods.PRICE_PUBLIC().call();
  price = price * n;
  const transactionParameters = {
      to: contractAddress, // Required except during contract publications.
      from: window.ethereum.selectedAddress, // must match user's active address.
      value: parseInt(price).toString(16),
      'data': window.contract.methods.mintMultipleNFT(n).encodeABI() //make call to NFT smart contract 
  };
  try {
      const txHash = await window.ethereum
          .request({
              method: 'eth_sendTransaction',
              params: [transactionParameters],
          });
      return {
          success: true,
          status: "✅ Check out your transaction on Etherscan: https://etherscan.io/tx/" + txHash
      }
  } catch (error) {
      return {
          success: false,
          status: "😥 Something went wrong: " + error.message
      }
  }
};

export const connectWallet = async () => {
  if (window.ethereum) {
    try {
      const addressArray = await window.ethereum.request({
        method: "eth_requestAccounts",
      });
      const obj = {
        status: "",
        address: addressArray[0],
      };
      return obj;
    } catch (err) {
      console.log("Failed to connect wallet :(", err);
      return {
        address: "",
        status: "😥 " + err.message,
      };
    }
  } else {
    return {
      address: "",
      status: (
        <span>
          <p>
            {" "}
            🦊{" "}
            <a target="_blank" href={`https://metamask.io/download.html`}>
              You must install Metamask, a virtual Ethereum wallet, in your
              browser.
            </a>
          </p>
        </span>
      ),
    };
  }
};

export const getCurrentWalletConnected = async () => {
  if (window.ethereum) {
    try {
      const addressArray = await window.ethereum.request({
        method: "eth_accounts",
      });
      if (addressArray.length > 0) {
        return {
          address: addressArray[0],
          status: "",
        };
      } else {
        return {
          address: "",
          status: "🦊 Connect to Metamask using the button below.",
        };
      }
    } catch (err) {
      return {
        address: "",
        status: "😥 " + err.message,
      };
    }
  } else {
    return {
      address: "",
      status: (
        <span>
          <p>
            {" "}
            🦊{" "}
            <a target="_blank" href={`https://metamask.io/download.html`}>
              You must install Metamask, a virtual Ethereum wallet, in your
              browser.
            </a>
          </p>
        </span>
      ),
    };
  }
};
