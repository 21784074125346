import {useEffect, useState} from "react";
import {connectWallet, getCurrentWalletConnected, mintNFT, mintMultipleNFT, refreshStats} from "./utils/interact.js";

const Minter = (props) => {

    function addWalletListener() {
        if (window.ethereum) {
            window.ethereum.on("accountsChanged", async (accounts) => {
                if (accounts.length > 0) {
                    setWallet(accounts[0]);
                    setStatus("");
                    await updateStats(accounts[0])
                } else {
                    setWallet("");
                    setStatus("🦊 Connect to Metamask using the button below.");
                }
            });
        } else {
            setStatus(
                <p>
                    {" "}
                    🦊{" "}
                    <a target="_blank" href={`https://metamask.io/download.html`}>
                        You must install Metamask, a virtual Ethereum wallet, in your
                        browser.
                    </a>
                </p>
            );
        }
    }

    function msecToStr(msec) {
        let hours = Math.floor(msec / (1000 * 60 * 60));
        let minutes = Math.floor((msec / 1000 / 60) % 60);
        let seconds = Math.floor((msec / 1000) % 60);
        return `${hours}h ${minutes}m ${seconds}s`;
    }


    //State variables
    const [walletAddress, setWallet] = useState("");
    const [status, setStatus] = useState("");
    const [stats, setStats] = useState("");
    const [walletPrice, setWalletPrice] = useState();
    const [totalSupply, setTotalSupply] = useState();
    const [totalLimit, setTotalLimit] = useState();
    const [balance, setBalance] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [allowPublic, setAllowPublic] = useState(false);
    const [now, setNow] = useState(new Date().getTime());
    useEffect(() => {
        setTimeout(() => {
            setNow(new Date().getTime());
        }, 1000);
    });

    useEffect(async () => {
        const {address, status} = await getCurrentWalletConnected();
        setWallet(address)
        setStatus(status);
        addWalletListener();
        await updateStats(address);
    }, []);

    const connectWalletPressed = async () => {
        const walletResponse = await connectWallet();
        setStatus(walletResponse.status);
        setWallet(walletResponse.address);
        await updateStats(walletResponse.address);
    };

    const onMintPressed = async (n) => {
        if (n === 1) {
            const {success, status} = await mintNFT();
            setStatus(status);
        } else {
            const {success, status} = await mintMultipleNFT(n);
            setStatus(status);
        }
    };

    const updateStats = async (address) => {
        setIsLoading(true);
        let {totalSupply, TOTAL_LIMIT, wallet_price, balance, allow_public} = await refreshStats(address);
        setAllowPublic(allow_public);
        // setAllowPublic(true);
        setTotalSupply(totalSupply);
        setTotalLimit(TOTAL_LIMIT);
        setWalletPrice(wallet_price);
        setBalance(balance);
        // setBalance(1);
        setIsLoading(false);
    };

    let deadline = 1653894000000;

    return (
        <div className="Minter">

            <h3>Chaotic Circles Mint Website</h3>

            <p>
                <a href={"https://yurycoding.art"}>Main</a>
                &nbsp;&nbsp;&nbsp;
                <a href={"https://etherscan.io/address/0x1CB8e2B38B8Af96E9B886B5D9F956F6eaD08f600"}>Contract</a>
                &nbsp;&nbsp;&nbsp;
                <a href={"https://twitter.com/yurycoding"}>Twitter</a>
                &nbsp;&nbsp;&nbsp;
                <a href={"https://instagram.com/yurycoding"}>Instagram</a>
                &nbsp;&nbsp;&nbsp;
                <a href={"https://discord.gg/yurycoding"}>Discord</a>
                &nbsp;&nbsp;&nbsp;
                <a href={"https://opensea.io/collection/chaotic-circles-by-yury"}>OpenSea</a>
            </p>
            {/*<h2>Mint</h2>*/}

            <p id="status">
                {status}
            </p>

            { walletAddress && <p>
                Connected: <b>{walletAddress}</b>
            </p> }

            { !walletAddress && <button id="walletButton" onClick={connectWalletPressed}>Connect Wallet</button> }

            { isLoading && <p>Reading contract...</p>}

            { !isLoading &&
            <div>

                { ((now < 1653894000000) && !allowPublic) &&
                <div>
                    <table>
                        <tr>
                            <td class={"tdHeader"}>Current stage</td>
                            <td><b>Pre-sale</b></td>
                        </tr>
                        <tr>
                            <td class={"tdHeader"}>Time left</td>
                            <td><b>{msecToStr(deadline - now)}</b></td>
                        </tr>
                        <tr>
                            <td class={"tdHeader"}>Price</td>
                            <td><s><b>0.12</b></s> <b>{walletPrice ? walletPrice : 0.1} ETH</b></td>
                        </tr>
                        <tr>
                            <td class={"tdHeader"}>Limit</td>
                            <td><b>1 per wallet</b></td>
                        </tr>
                        <tr>
                            <td className={"tdHeader"}>Total minted</td>
                            <td><b>{totalSupply} / {totalLimit}</b></td>
                        </tr>
                    </table>

                    { !walletPrice &&
                    <div>
                        <p>You can't mint at the moment (you're not on the list).</p>
                        <p>You can mint when the public sale starts in {msecToStr(deadline - now)}.</p>
                    </div>}

                </div>
                }

                { ((now >= 1653894000000) && !allowPublic) &&
                <div>
                    <p>Public mint will start in a few minutes. Please reload the page a bit later.</p>
                </div>
                }

                { allowPublic &&
                <div>
                    <table>
                        <tr>
                            <td className={"tdHeader"}>Current stage</td>
                            <td><b>Public</b></td>
                        </tr>
                        <tr>
                            <td className={"tdHeader"}>Price</td>
                            <td><b>0.12 ETH</b></td>
                        </tr>
                        <tr>
                            <td className={"tdHeader"}>Total minted</td>
                            <td><b>{totalSupply} / {totalLimit}</b></td>
                        </tr>
                    </table>
                </div>
                }

                { (balance > 0) && <p>You have minted {balance} circle{balance > 1 ? "s" : ""}. 🥳 <br/></p> }
                { (balance > 0 && !allowPublic) && <p>You can mint more when the public mint starts in {msecToStr(deadline - now)}.</p> }


                {walletPrice > 0 &&
                <div>
                    {(allowPublic ? [1, 3, 5, 10, 20].filter(n => n + totalSupply <= totalLimit) : (balance == 0 ? [1] : [])).map(n => {
                        let suffix = {
                        }[n];
                        if (!suffix) suffix = "";

                        return (<button key={n} id="mintButton" className="mintBtn" onClick={async () => {
                            await onMintPressed(n)
                        }}>Mint {n} x Chaotic Circle{n > 1 ? "s" : ""} for {(n > 1 ? 0.12 * n : walletPrice).toFixed(2)} ETH {suffix}</button>)
                    })}
                </div>
                }
            </div>
            }

        </div>
    );
};

export default Minter;
